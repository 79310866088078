import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Stripe from "./Stripe";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import "./style.styl";


export default function StripeDialog(props) {
    const {open, onClose, amount, ecommerceStoreId, ecommerceOrderId, ecommerceStoreDeliveryOptionPickupId, onSuccess, onCancel, apiPath, isMobile} = props;
    const [publicKey, setPublicKey] = useState("");

    const getPublicKey = () => {
        if (!ecommerceStoreId) {
            return;
        }
        dataSourceV2(`customer_payment_stripe_token_get?ecommerce_store_id=${ecommerceStoreId}&ecommerce_store_delivery_option_pickup_id=${ecommerceStoreDeliveryOptionPickupId || ""}&ecommerce_order_id=${ecommerceOrderId || ""}`, {url: apiPath})
            .then(v => {
                if (v.token) {
                    setPublicKey(v.token);
                }
            });
    };

    useEffect(() => {
        getPublicKey();
    }, []);



    return (
        <Dialog
          fullScreen={isMobile}
          onClose={() => {onCancel();onClose();}}
          open={open}
          maxWidth="sm"
          fullWidth
          className="tree-widget1 cart-dialog"
          scroll="paper"
        >
            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter u-paddingLeft24">
                    <DialogTitle className="u-paddingLeft0 u-marginLeft5">Stripe</DialogTitle>
                </div>
                <div>
                    <IconButton onClick={() => {onCancel();onClose();}} className="u-marginRight10">
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="u-paddingTop3">
                {
                    publicKey ?
                        <Stripe {...props}
                                amount={amount}
                                publicKey={publicKey}
                                ecommerceStoreId={ecommerceStoreId}
                                onSuccess={(paymentData) => {onClose();onSuccess(paymentData);}}
                                apiPath={apiPath}/>
                            :
                            <Loading/>
                }
            </DialogContent>
        </Dialog>
    );

}
