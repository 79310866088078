import React, {useState, useEffect} from "react";
import {Form, withFormik} from "formik";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import FormLabel from "@material-ui/core/FormLabel";
import {dataSourceV2} from "commons-ui/core/DataSource";
import "./style.styl";



function GravityForm(props) {
    const {values, errors, touched, onSubmit, onSuccess, setValues, setFieldValue, setFieldError, ecommerceStoreId, apiPath} = props;

    console.log(values, errors);

    return (
        <Form>
            <div className="u-sizeFullWidth u-xs-maxWidthNone u-paddingTop15">
                <TextField
                    error={errors.billing_name && touched.billing_name}
                    helperText={errors.billing_name && touched.billing_name ? errors.billing_name : ""}
                    label="Billing Name"
                    margin="dense"
                    value={values.billing_name}
                    className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                    onChange={(e) => setFieldValue("billing_name", e.target.value)}
                    variant="outlined"
                />

                <TextField
                    error={errors.billing_address && touched.billing_address}
                    helperText={errors.billing_address && touched.billing_address ? errors.billing_address : ""}
                    label="Billing Address"
                    margin="dense"
                    value={values.billing_address}
                    className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                    onChange={(e) => setFieldValue("billing_address", e.target.value)}
                    variant="outlined"
                />

                <TextField
                    error={errors.billing_postal_code && touched.billing_postal_code}
                    helperText={errors.billing_postal_code && touched.billing_postal_code ? errors.billing_postal_code : ""}
                    label="Billing Postal Code"
                    margin="dense"
                    value={values.billing_postal_code}
                    className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                    onChange={(e) => setFieldValue("billing_postal_code", e.target.value)}
                    variant="outlined"
                />
            </div>

            <div className="u-flexCenter u-justifyContentCenter u-paddingVertical15">
                <Button color="secondary" size="large" variant="outlined" type="submit" className="u-minWidth120">
                    Pay
                </Button>
            </div>
        </Form>
    );

}


export default GravityForm = withFormik({
    mapPropsToValues: (props) => ({
        billing_name: "",
        billing_address: "",
        billing_postal_code: ""
    }),

    validate: (values, props) => {
        let errors = {};


        if (!values.billing_name) {
            errors.billing_name = "Cannot be empty";
        }
        if (values.billing_name.length >= 75) {
            errors.billing_name = "Max length: 75";
        }
        if (!values.billing_address) {
            errors.billing_address = "Cannot be empty";
        }
        if (!values.billing_postal_code) {
            errors.billing_postal_code = "Cannot be empty";
        }

        console.log("VALIDATE", errors);

        return errors;
    },

    handleSubmit: (values, props) => {
        const {onPay, apiPath} = props.props;
        console.log(123, "NO ERRORS");
        onPay(values);

    }

})(GravityForm);
