import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/icons/RadioButtonCheckedTwoTone";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Divider } from "@material-ui/core";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "white",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

const styles = (theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1.5fr 0.5fr",
    gridTemplateAreas: '"Content" "ContentToHide"',
    border: "1px solid black",
    overflow: "hidden",
  },

  content: {
    height: "7vh",
    gridArea: "Content",
    backgroundColor: "beige",
    transition: "height 0.1s ease-out 0.1s",
  },

  contentExpand: {
    height: "100vh",
  },

  contentToHide: {
    height: "5vh",
    gridArea: "ContentToHide",
    backgroundColor: "yellow",
  },

  contentToHideHidden: {
    overflow: "hidden",
  },

  div1: {
    paddingLeft: "20px",
  },
});

function Image(props) {
  const { path } = props;
  const style = {
    backgroundImage: `url(${path})`,
    backgroundSize: true ? "cover" : "contain",
    height: "125px",
  };

  return (
    <div
      className={
        "u-relative u-inlineBlock u-verticalAlignTop " +
        (true || true ? "u-marginBottom4" : "u-marginVertical4")
      }
    >
      <div
        className={
          "image-widget-item u-relative u-sizeFullWidth u-cursorPointer "
        }
        style={style}
      ></div>
    </div>
  );
}

export default function BottomAppBar(props) {
  const {
    match,
    location,
    categoryData,
    isMobile,
    apiPath,
    mediaPath,
    dispatch,
    ecommerceStoreId,
  } = props;
  console.log("CART", props);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [toggled, setToggled] = React.useState(true);
  const [itemList, setItemList] = useState([]);

  const toggle = () => {
    setToggled(!toggled);
  };

  const categoryRequest = async (v) => {
    const query = {
      size: 10,
      sort: [],
      _source: ["search_result_data"],
      query: {
        bool: {
          must: [],
          filter: [
            //{ "term":  { "search_data.ecommerce_store_group_id": ecommerceStoreGroupId }}
          ],
          must_not: [{ term: { "hidden.id_list": "default" } }],
        },
      },
    };

    if (true) {
      query["sort"].push({ "search_data.created_at": { order: "desc" } });
    }

    const category = v.ecommerce_product_category || {};
    const id = category.ecommerce_product_category_id;
    const name = category.name;
    query["query"]["bool"]["filter"].push({
      term: {
        "category.id_list":
          v.ecommerce_product_category.ecommerce_product_category_id,
      },
    });
    let newItemList = ["1"];
    const data = await dataSourceV2(
      `ecommerce_product_view_list?is_search=true&postprocess=default&query=${JSON.stringify(
        query
      )}`,
      { url: apiPath }
    ).then((v) => {
      return v;
      if (!v.message && v.item_list) {
        newItemList = v.item_list.map((data) => {
          const category_id_dict = data.category_id_dict || {};
          const version = data.version || {};

          return {
            ...version,
            category_id_dict,
            attribute_set_id: data.attribute_set_id,
            ecommerce_product_id: data.ecommerce_product_id,
          };
        });
        return newItemList;
      }
    });
    return data;
  };

  useEffect(() => {
    async function fetchMyAPI() {
      const allofthem = await (
        (categoryData.categoryDict || {}).main || []
      ).map((v, i) => {
        return categoryRequest(v).then((v) => {
          return v;
        });
      });
      const p = await Promise.all(allofthem);
      let promiseArrays = [];
      p.map((items) => {
        items.item_list.map((item) => {
          promiseArrays.push(item);
        });
      });

      setItemList(promiseArrays);
    }
    fetchMyAPI();
  }, [categoryData]);

  return (
    <React.Fragment>
      <div>
        <AppBar
          position="fixed"
          color="white"
          style={
            toggled
              ? { height: "40vh", backgroundColor: "white" }
              : { height: "55px" }
          }
          className={classes.appBar}
        >
          <Toolbar style={{ marginBottom: "3px", minHeight: "50px" }}>
            <IconButton
              style={{ padding: "0px", margin: "0px" }}
              onClick={() => toggle()}
              edge="end"
              color="inherit"
              aria-label="open drawer"
            >
              {toggled ? (
                <KeyboardArrowDownIcon
                  style={{ fontSize: "20px", padding: "0px", margin: "0px" }}
                />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </IconButton>
          </Toolbar>
          <Divider />
          {itemList.length > 0 ? (
            <div
              style={{
                paddingLeft: "20px",
                marginTop: "2px",
                overflow: "scroll",
                marginBottom: "5px",
                color: "white",
                backgroundColor: "white",
              }}
            >
              <Grid container spacing={2}>
                {itemList.slice(0, 6).map((item) => {
                  const image = item.version.images
                    ? mediaPath + (item.version.images || "").split(",")[0]
                    : "";
                  item.version.attribute_set_id = item.attribute_set_id;
                  item.version.category_id_dict = item.category_id_dict;
                  item.version.ecommerce_product_id = item.ecommerce_product_id;

                  return (
                    <Grid item xs={2}>
                      <div className="tmallItem product-gallery-item u-sizeFullHeight">
                        <div className="u-flexColumn u-justifyContentStart u-sizeFullHeight u-relative">
                          <Image path={image || "/static/img/no-image.png"} />
                          <div className="u-flexCenter u-justifyContentCenter u-sizeFullHeight">
                            <div className="u-marginTop0 u-marginBottom0 u-paddingHorizontal0 u-textAlignCenter u-fontSize13 u-textColorDarker">
                              {item.version.name}
                            </div>
                          </div>
                          <div className="u-marginTop0 u-marginBottom0 u-paddingHorizontal10 u-textAlignCenter u-fontSize16 u-textColorDarker">
                            ${item.version.price}
                          </div>

                          <div
                            className={
                              "u-sizeFullWidth u-sizeFullHeight u-relative u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5 u-paddingBottom10 u-paddingTop0 " +
                              (isMobile
                                ? "u-paddingHorizontal10"
                                : "u-paddingHorizontal15")
                            }
                          >
                            <div className="u-flexCenter">
                              <div>
                                <button
                                  style={{
                                    fontSize: "13px",
                                    fontFamily:
                                      "font-family: Arial, Helvetica, sans-serif",
                                  }}
                                  className="button-secondary"
                                  link-meta="/checkout"
                                  onClick={() =>
                                    dispatch(
                                      "cart",
                                      {
                                        product: item.version,
                                        count: 1,
                                        ecommerceStoreId,
                                      },
                                      "add_product"
                                    )
                                  }
                                >
                                  ADD
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <div></div>
          )}
        </AppBar>
      </div>
    </React.Fragment>
  );
}
