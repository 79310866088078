import React, {useState, useEffect} from "react";
import GravityForm from "./GravityForm";
import {dataSourceV2} from "commons-ui/core/DataSource";
import "./style.styl";



export default function Gravity(props) {
    const {onPay, paymentDynamic, isPaymentSend, amount, paymentResultPolling, ecommerceStoreId, onSuccess, apiPath} = props;
    //const [isPaymentSend, setIsPaymentSend] = useState(false);
    const [error, setError] = useState("");




    return (
        <div>
            <GravityForm {...props} onPay={onPay}/>
                {error && (
                  <div className="u-textColorRed u-marginTop10">
                    {error}
                  </div>
                )}
        </div>
    );

}
