import React, {useState, useEffect} from "react";
import StripeDialog from "./StripeDialog";
import GravityDialog from "./GravityDialog";
import WalletDialog from "./WalletDialog";
import ErrorDialog from "./ErrorDialog";
import "./style.styl";



export default function PaymentGateway(props) {
    const {open, provider, ecommerceOrderId, onClose, amount, onCancel, ecommerceStoreDeliveryOptionPickupId, ecommerceStoreId, isPaymentSend, isMobile, onSuccess, apiPath} = props;
    const [errorOpen, setErrorOpen] = useState(false);
    const [checkOrder, setCheckOrder] = useState(false);
    const isFake = amount === 0
    
    

    useEffect(() => {
        if (!open) return;
        setCheckOrder(false);
        const timeout = setTimeout(() => {
            setCheckOrder(true);
        }, 15000);
        return () => clearTimeout(timeout);
    }, [open]);

    useEffect(() => {
        if (checkOrder && !ecommerceOrderId) {
            setErrorOpen(true);
        }
    }, [checkOrder]);

    const renderProvider = () => {
        if (isFake) {
            return null
        }
        if (open && (!ecommerceOrderId || errorOpen)) {
            return (
                <ErrorDialog   open={true}
                                amount={amount}
                                onSuccess={onSuccess}
                                onCancel={onCancel}
                                loading={!errorOpen}
                                onClose={() => {setErrorOpen(false);onClose();}}
                                isMobile={isMobile}
                                ecommerceOrderId={ecommerceOrderId}
                                ecommerceStoreId={ecommerceStoreId}
                                apiPath={apiPath}/>
            );
        }
        if (provider === "stripe") {
            return (
                <StripeDialog   {...props}
                                open={open}
                                amount={amount}
                                onSuccess={onSuccess}
                                onCancel={onCancel}
                                onClose={onClose}
                                isMobile={isMobile}
                                ecommerceOrderId={ecommerceOrderId}
                                ecommerceStoreDeliveryOptionPickupId={ecommerceStoreDeliveryOptionPickupId}
                                ecommerceStoreId={ecommerceStoreId}
                                apiPath={apiPath}/>
            );
        }
        if (provider === "gravity") {
            return (
                <GravityDialog   {...props}
                                open={open}
                                isPaymentSend={isPaymentSend}
                                amount={amount}
                                onSuccess={onSuccess}
                                onCancel={onCancel}
                                onClose={onClose}
                                isMobile={isMobile}
                                ecommerceOrderId={ecommerceOrderId}
                                ecommerceStoreId={ecommerceStoreId}
                                apiPath={apiPath}/>
            );
        }

        if (provider === "apple" || provider === "google" || provider === "wallets") {
            return (
                <WalletDialog   {...props}
                                open={open}
                                isPaymentSend={isPaymentSend}
                                amount={amount}
                                onSuccess={onSuccess}
                                onCancel={onCancel}
                                onClose={onClose}
                                isMobile={isMobile}
                                ecommerceOrderId={ecommerceOrderId}
                                ecommerceStoreId={ecommerceStoreId}
                                apiPath={apiPath}/>
            );
        }

        return null;
    };

    return (
        <div>
            {renderProvider()}
        </div>
    );

}
