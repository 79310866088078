import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Gravity from "./Gravity";
import "./style.styl";


export default function GravityDialog(props) {
    const {open, ecommerceOrderId, onClose, isMobile, paymentDynamic, isPaymentSend, amount, onCancel, paymentResultPolling, ecommerceStoreId, onSuccess, recaptchaKey, apiPath} = props;
    const [externalTransactionId, setExternalTransactionId] = useState("");
    const [paymentStatusCheckAt, setPaymentStatusCheckAt] = useState("");

    const onCheckPaymentStatus = (externalTransactionId) => {
        console.log("POLL");
        if (!isPaymentSend) {
            return;
        }
        console.log("POLL SENT");
        dataSourceV2(`customer_payment_status_poll?external_transaction_id=${externalTransactionId}`, {url: apiPath})
            .then(v => {
                if (v.status) {
                    if (v.status === "approved") {
                            console.log("APPROVED", v)
                            onSuccess();
                            window.emergepay.close();
                        //if (!values.is_payment_done) {
                            //onSuccess();
                            //setFieldValue("is_payment_done", true).then(v => submitForm());
                        //}
                        //onSuccess({})
                    } else if (v.status === "declined") {
                        //props.setSubmitting(false);
                        //setFieldValue("is_payment", false);
                    } else {
                    }
                }
            });
    };

    const onPaymentConfirm = (payment) => {
        if (!paymentResultPolling) {
            dataSourceV2(paymentDynamic ? "customer_payment_dynamic_success" : "customer_payment_success", {data: {ecommerce_store_id: ecommerceStoreId, item: payment}, url: apiPath})
                .then(v => {
                    if (v.message || v.status) {
                        console.log("ERROR", v.message);
                    } else {
                        //console.log(values.payment);
                    }
                    //props.setSubmitting(false);
                });
        }
    };

    const onCardWidgetOpen = (data) => {


        if (!window.emergepay) return;
        if (window.grecaptcha) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(recaptchaKey, {action: 'payment_start'}).then(function(token) {
                    console.log("Google TOKEN", token)

                    onClose();
                    //props.setSubmitting(true);
                    window.emergepay.init();
                    dataSourceV2(paymentDynamic ? "customer_payment_dynamic_start" : "customer_payment_start", {data: {ecommerce_order_id: ecommerceOrderId, amount: parseFloat(amount.toFixed(2)) || 0, billing_name: data.billing_name, billing_address: data.billing_address, billing_postal_code: data.billing_postal_code, captcha_token: token}, url: apiPath})
                        .then(v=>{
                            if (v.message) {
                            } else if (v.token) {
                                setExternalTransactionId(v.external_transaction_id || "");
                                window.emergepay.open({
                                            // (required) Used to set up the modal
                                            transactionToken: v.token,
                                            // (optional) Callback function that gets called after a successful transaction
                                            onTransactionSuccess: (approvalData) => {
                                                console.log("Approval Data", approvalData);
                                                const paymentData = {credit_card_id: approvalData.uniqueTransId, unique_transaction_id: approvalData.uniqueTransId, external_transaction_id: approvalData.externalTransactionId, credit_card_number: approvalData.maskedAccount, credit_card_expiration: approvalData.accountExpiryDate, credit_card_type: approvalData.accountExpiryDate};
                                                onPaymentConfirm(paymentData);
                                                onSuccess(paymentData);
                                                if (!paymentResultPolling) {
                                                    const paymentData = {credit_card_id: approvalData.uniqueTransId, unique_transaction_id: approvalData.uniqueTransId, external_transaction_id: approvalData.externalTransactionId, credit_card_number: approvalData.maskedAccount, credit_card_expiration: approvalData.accountExpiryDate, credit_card_type: approvalData.accountExpiryDate};
                                                    onPaymentConfirm(paymentData);
                                                    onSuccess(paymentData);
                                                    //setFieldValue("payment", paymentData);
                                                    //if (!values.is_payment_done) {
                                                    //    setFieldValue("is_payment_done", true).then(v => onSuccess(paymentData));
                                                    //}
                                                    //onSuccess(paymentData)
                                                } else {
                                                    //if (!values.is_payment_done) {
                                                        //setFieldValue("is_payment_done", true).then(v => submitForm())
                                                    //}
                                                    //onSuccess({})
                                                }
                                                window.emergepay.close();
                                            },
                                            // (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
                                            onTransactionFailure: (failureData) => {
                                                console.log("Failure Data", failureData);
                                                setExternalTransactionId("");
                                                setError("Error");
                                                onCancel();
                                                //props.setSubmitting(false);
                                                //setFieldValue("is_payment", false);
                                            },
                                            // (optional) Callback function that gets called after a user clicks the close button on the modal
                                            onTransactionCancel: function() {
                                                console.log("transaction cancelled!");
                                                setExternalTransactionId("");
                                                onCancel();
                                                //props.setSubmitting(false);
                                                //setFieldValue("is_payment", false);
                                            }
                                        });
                            }
                        });
                });
            });
        } else {
            setExternalTransactionId("");
            setError("Error");
            onCancel();
            onClose();
        }
        
    };

    useEffect(() => {
      const timer = setTimeout(() => {
          setPaymentStatusCheckAt(new Date().toISOString());
      }, 2000);
      return () => clearTimeout(timer);
    }, [externalTransactionId, paymentStatusCheckAt]);


    useEffect(() => {
        if (externalTransactionId && paymentResultPolling) onCheckPaymentStatus(externalTransactionId);
    }, [paymentStatusCheckAt]);

    return (
        <Dialog
          fullScreen={isMobile}
          onClose={() => {onCancel();onClose();}}
          open={open}
          maxWidth="sm"
          fullWidth
          className="tree-widget1 cart-dialog"
          scroll="paper"
        >
            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter u-paddingLeft24">
                    <DialogTitle className="u-paddingLeft0 u-marginLeft5">Billing Information</DialogTitle>
                </div>
                <div>
                    <IconButton onClick={() => {onCancel();onClose();}} className="u-marginRight10">
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="u-paddingTop3">
                <Gravity {...props} apiPath={apiPath} onPay={onCardWidgetOpen}/>
            </DialogContent>
        </Dialog>
    );

}
