import React, {useState, useEffect, useRef} from "react";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Loading from "commons-ui/core/Loading";
import "./style.styl";


export default function WalletDialog(props) {
    const {values, open, ecommerceOrderId, onClose, noDialog, isMobile, paymentDynamic, isPaymentSend, amount, onCancel, paymentResultPolling, ecommerceStoreId, onSuccess, onPrepay, apiPath} = props;
    const [wallets, setWallets] = useState(new emergepayWallets("f96e4e1d60abf19261c6")); //f96e4e1d60abf19261c6 17b1a496ef46fbbcf2a5 sand)
    const amountRef = useRef();
    const ecommerceOrderIdRef = useRef();
    amountRef.current = amount;
    ecommerceOrderIdRef.current = ecommerceOrderId


    const onDetails = () => {
        const details = {
          total: amount.toFixed(2),
          shippingMethods: [
                { id: "S1", label: "Shipping", description: "Default", amount: "0.00" },
          ],
          lineItems: [
                values.base_amount ? { label: "Base Amount", amount: values.base_amount.toFixed(2) } : null,
                values.labeling_amount ? { label: "Labeling Amount", amount: values.labeling_amount.toFixed(2) } : null,
                values.delivery_amount ? { label: "Delivery Amount", amount: values.delivery_amount.toFixed(2) } : null,
                values.packaging_amount ? { label: "Packaging Amount", amount: values.packaging_amount.toFixed(2) } : null,
                values.tips_amount ? { label: "Tips Amount", amount: values.tips_amount.toFixed(2) } : null,
                values.tax_amount ? { label: "Tax Amount", amount: values.tax_amount.toFixed(2) } : null
            ].filter(v => !!v)
        }
        return details
    }

    const onInit = (reinit) => {
      console.log("INIT WALLETS")
      const details = onDetails();

      wallets.setRequiredFields({
          billing: {
              address: true,
          },
      }).catch(err => console.log(err.data));



      wallets.setTransactionDetails(details).catch(err => console.log(err.data));

      wallets.appendButtons({
          appendToId: "wallets-container",//"wallets-container",
          color: "black",
          type: "normal",
          inheritDimensionsFromId: "checkout",
      }).catch(err => console.log(err.data));

      wallets.oncancel = () => {
        console.log("wallet transaction canceled");
        onCancel();
      }

      wallets.onerror = error => {
        console.log(error);
        onCancel();
      }

      wallets.onuserauthorized = response => {
          console.log("onuserauthorized", response)
          if (!response.shipping.method) {
            response.shipping.method = details.shippingMethods[0]
          }
          const amount = amountRef.current
          onPrepay().then(() => {
            const ecommerceOrderId = ecommerceOrderIdRef.current
            if (!ecommerceOrderId) {
                onCancel()
            }
            const data = {ecommerce_order_id: ecommerceOrderId || "", amount: amount.toFixed(2), wallet_data: response}
            dataSourceV2(`customer_payment_wallet_confirm`, {data: data, url: apiPath})
                .then(v => {
                    if (!v.message && v.status !== 500) {
                        console.log("WALLET RESP", v)
                        onSuccess({is_wallet: true})
                        wallets.completePayment({ approved: true });
                    } else {
                        onCancel()
                    }
                });
          })
      };

      wallets.onshippinginfoupdate = shippingInfo => {
            console.log(2, shippingInfo);
            wallets.updateShippingInfo(details);
        };

      wallets.onshippingmethodupdate = shippingMethod => {
            console.log(1, shippingMethod);
            const newDetails = {...details}
            delete newDetails.shippingMethods
            wallets.updateShippingMethod(newDetails);
        };

        setWallets(wallets)
    }

    useEffect(() => {
        if (wallets) {
          wallets.setTransactionDetails(onDetails()).catch(err => console.error(err.data));
        } else {
          console.error("no wallets when update transaction details")
        }
    }, [amount.toFixed(2)])




    useEffect(() => {
        onInit()
    }, [])


    if (noDialog) {
        return (
          <div id="wallets-container">
              <button id="checkout" style={{display: "none"}}>Checkout</button>
          </div>
        )
    }

    return (
        <Dialog
          onClose={() => {onCancel();onClose();}}
          open={open}
          maxWidth="sm"
          fullWidth
          className="tree-widget1 cart-dialog"
          scroll="paper"
        >
            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter u-paddingLeft24">
                    <DialogTitle className="u-paddingLeft0 u-marginLeft5">Digital Wallets</DialogTitle>
                </div>
                <div>
                    <IconButton onClick={() => {onCancel();onClose();}} className="u-marginRight10">
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="u-paddingTop3">
                <div className="u-flexCenter u-justifyContentCenter u-marginVertical50">
                    <div id="wallets-container">
                        <button id="checkout" style={{display: "none"}}>Checkout</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );

}
