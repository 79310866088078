import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  Elements
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {dataSourceV2} from "commons-ui/core/DataSource";
import "./style.styl";

export default function Stripe(props) {
    const {publicKey} = props;
    const [stripePromise, setStripePromise] = useState(loadStripe(publicKey));
    return (
        <Elements stripe={stripePromise}>
            <StripeBase {...props}/>
        </Elements>
    );
}
export function StripeBase(props) {
    const {amount, ecommerceStoreId, ecommerceOrderId, publicKey, recaptchaKey, onSuccess, apiPath} = props;
    //const [stripePromise, setStripePromise] = useState(loadStripe(publicKey));
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState("");
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const elements = useElements();

  const onPaymentStart = () => {
    if (window.grecaptcha) {
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(recaptchaKey, {action: 'payment_start'}).then(function(token) {
                console.log("Google TOKEN", token)
                dataSourceV2("customer_payment_start_stripe", {data: {amount: amount, ecommerce_store_id: ecommerceStoreId, ecommerce_order_id: ecommerceOrderId, captcha_token: token}, url: apiPath})
                  .then(v => {
                      if (v.token) {
                          setClientSecret(v.token);
                          console.log("TOKEN", v.token);
                      }
                  });
            })
        });
    } else {
        setExternalTransactionId("");
        setError("Error");
        onCancel();
        onClose();
    }
  };

  const onChangeCreditCardPayment = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const onSubmitCreditCardPayment = async ev => {
    ev.preventDefault();
    setProcessing(true);
    console.log("secret", clientSecret);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      console.log("PAYLOAD", payload);
      onSuccess();

    }
  };

  const onWalletsInit = () => {
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: Math.round(amount * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        pr.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
            clientSecret,
            {payment_method: ev.paymentMethod.id},
            {handleActions: false}
          );
        
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const {error} = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
              } else {
                onSuccess();
              }
            } else {
                onSuccess();
            }
          }
        });
        setPaymentRequest(pr);
      }
    });
  }

  useEffect(() => {
    onPaymentStart();
  }, []);

  useEffect(() => {
    if (stripe && clientSecret) {
      onWalletsInit()
    }
  }, [stripe, clientSecret]);


  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  return (

    <div> 
      <form id="payment-form" onSubmit={onSubmitCreditCardPayment}>
        <CardElement id="card-element" options={cardStyle} onChange={onChangeCreditCardPayment} />
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error u-textColorRed u-marginTop10" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded
        </p>
      </form>

      {
            paymentRequest && clientSecret ?
              <div className="u-marginTop20">
                <PaymentRequestButtonElement options={{paymentRequest}} />
              </div>
              :
              null
          }
    </div>
  );
}
