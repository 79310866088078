import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import "./style.styl";


export default function ErrorDialog(props) {
    const {open, loading, ecommerceOrderId, onClose, isMobile, paymentDynamic, isPaymentSend, amount, onCancel, paymentResultPolling, ecommerceStoreId, onSuccess, apiPath} = props;


    return (
        <Dialog
          fullScreen={isMobile}
          onClose={() => {onCancel();onClose();}}
          open={open}
          maxWidth="sm"
          fullWidth
          className="tree-widget1 cart-dialog"
          scroll="paper"
        >

            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter u-paddingLeft24">
                    {
                        loading ?
                            <DialogTitle className="u-paddingLeft0">Loading...</DialogTitle>
                            :
                            <DialogTitle className="u-paddingLeft0 u-textColorRed">Error Message</DialogTitle>
                    }
                </div>
                <div>
                    <IconButton onClick={() => {onCancel();onClose();}} className="u-marginRight10">
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="u-paddingTop3">
                {
                    loading ?
                        <Loading className="u-marginBottom100"/>
                        :
                        <div>
                            <h2 className="u-marginTop15 u-marginBottom10">Something went wrong! Error Code #500</h2>
                            <span>
                                Please refresh page and try again. If issue persists, please call 206-764-1000 or email cs@piroshkybakery.com. We apologize for any inconvenience.
                            </span>
                        </div>
                }
            </DialogContent>
        </Dialog>
    );

}
